
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import Pagination from "@/comp/Pagination/index.vue";
import { videoSupervise, videoSuperviseType, mediumInterrupt } from "@/api/request/supervise";

//组件
@Component({
  name: "Online",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private options: any[] = [];
  private listLoading: boolean = true; // 列表加载

  //请求数据
  private listQuery: any = {
    //页码数据
    row: 20,
    page: 1,

    //临时数据
    time: [],

    //请求数据
    end_time: "",
    start_time: "",
    userid: undefined,
    risk_type: undefined,
    bloggerid: undefined,
  };

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //创建
  created() {
    //初始化时间
    this.initTime();

    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
    } else {
      this.listQuery.start_time = "";
      this.listQuery.end_time = "";
    }
    if (this.listQuery.userid) this.listQuery.userid = Number(this.listQuery.userid);
    if (this.listQuery.risk_type) this.listQuery.risk_type = Number(this.listQuery.risk_type);
    if (this.listQuery.bloggerid) this.listQuery.bloggerid = Number(this.listQuery.bloggerid);

    //获取数据
    const { data } = await videoSupervise(this.listQuery);

    //数据赋值
    data.list.map((item: any) => {
      if (item.user_a.is_promoter) {
        return (item.promoter = item.user_a), (item.receiver = item.user_b);
      } else {
        return (item.promoter = item.user_b), (item.receiver = item.user_a);
      }
    });

    //获取类型
    const res = await videoSuperviseType();

    //数据赋值
    this.list = data.list;
    this.total = data.total;
    this.options = res.data;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //初始化时间
  private initTime(): void {
    //数据赋值
    this.listQuery.end_time = `${DTCls.getCurDateTimeYMD()}`;
    this.listQuery.start_time = `${DTCls.getCurDataTimeYMD7()}`;
    this.listQuery.time = [`${this.listQuery.start_time}`, `${this.listQuery.end_time}`];
    this.listQuery.start_time += "  00:00:00";
    this.listQuery.end_time += "  23:59:59";
  }

  //处理重置查询
  private handleReset(): void {
    //重置时间
    this.initTime();

    //数据赋值
    this.listQuery.userid = undefined;
    this.listQuery.risk_type = undefined;
    this.listQuery.bloggerid = undefined;

    //获取数据
    this.getList();
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //处理中断
  private handleInterrupt(row: any) {
    //显示提示
    this.$confirm(`确定中断?`, "Warning", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //显示等待
        this.listLoading = true;

        //请求数据
        const res = await mediumInterrupt({ room_id: row.room_id });

        //获取数据
        this.getList();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //改变类型
  private typeChange(val: any): void {
    //数据赋值
    this.listQuery.risk_type = val;
  }

  //处理状态
  private formatStatus(row: any): string {
    if (row.connect_status === 0) return "通话结束";
    if (row.connect_status === 1) return "通话进行中";
    return "";
  }
}
